import React, { useState, useEffect } from "react";
import { storage, db } from "../Firebase/config";
import { doc, setDoc, collection } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default function useStorage(file, colName, numTickets, cost, method) {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);
  const [docId, setDocId] = useState(null);

  useEffect(() => {
    const storageRef = ref(storage, file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    const unsubscribe = uploadTask.on(
      "state_changed",
      (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgress(percentage);
      },
      (err) => {
        setError(err);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        const url = downloadURL;
        const createdAt = new Date();
        const colRef = collection(db, colName);
        const docRef = doc(colRef);
        const res = await setDoc(docRef, {
          paymentUrl: url,
          admit: numTickets,
          createdAt,
          cost,
          method,
          status: "pending validation",
        });

        // Access id from the DocumentReference

        setDocId(docRef.id);

        setUrl(url);
      }
    );

    // Cleanup function
    return () => {
      // Cancel the ongoing upload task
      uploadTask.cancel();
      // Unsubscribe from the state_changed event
      unsubscribe();
    };
  }, [file]);
  return { progress, url, docId, error };
}
