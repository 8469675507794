import React, { useState, useEffect, useRef } from "react";
import ticketTemplate from "../Assets/ticketTemplate.jpg";
import { useParams } from "react-router-dom";
import TicketQr from "../components/TicketQr";
import { getDocData } from "../Firebase/config";
import html2canvas from "html2canvas";


function TicketRender() {
  const { id } = useParams();
  const path = `https://mbs-camasa2023.vercel.app/ticketview/${id}`;
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const [ticketData, setTicketData] = useState({});
  const [ticketCount, setTicketCount] = useState(1);
  const [ticketNumber, setTicketNumber] = useState(0);
 

  const ticketRef = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.src = ticketTemplate;
    img.onload = () => {
      setImgSize({ width: img.width, height: img.height });
    };
  }, []);

  useEffect(() => {
    const getTicketData = async () => {
      const data = await getDocData("tickets", id);
      console.log(data);
      setTicketData(data);
    };
    getTicketData();

    console.log(ticketData);
  }, []);

  useEffect(() => {
    try {
      const firsTix = ticketData?.ticketNumber[0];
      setTicketNumber(firsTix);
    } catch (error) {}
  }, [ticketData]);

  const handleTicketCount = () => {
    setTicketCount(ticketCount + 1);
    setTicketNumber(ticketNumber + 1);
  };

  const handlePreviousTicket = () => {
    setTicketCount(ticketCount - 1);
    setTicketNumber(ticketNumber - 1);
  };

  const { admit } = ticketData;

  const handleDownload = () => {
    html2canvas(ticketRef.current).then((canvas) => {
      // Use a callback to ensure the canvas is fully rendered
      canvas.toBlob((blob) => {
        const url = window.URL.createObjectURL(blob);

        // Create a temporary <a> element to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = `ticket_${ticketNumber} - ${ticketData.name}.jpg`;

        // Trigger a click event on the <a> element
        a.click();

        // Clean up the temporary <a> element and revoke the URL
        window.URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <div className="flex flex-col items-center justify-center " >
      <div ref={ticketRef}>
        <div className="relative">
          <img
            className="lg:w-96 w-96 "
            src={ticketTemplate}
            alt=""
            onLoad={(e) => {
              setImgSize({
                width: e.target.width,
                height: e.target.height,
              });
            }}
          />
          <p className="font-bold text-black bg-white absolute bottom-2 left-1 p-1 ">
            NO. {ticketNumber}
          </p>
          <p className="font-bold text-black bg-white absolute bottom-2 right-1 p-1 w-24 text-center">
            ADMIT 1
          </p>
          <div
            className="flex flex-col items-center justify-center absolute w-40"
            style={{
              left: "50%", // Center horizontally
              top: "56%", // Center vertically
              transform: "translate(-50%, 25%)", // Center the absolute container
            }}
          >
            <TicketQr
              value={path}
              size={Math.min(imgSize.width, imgSize.height) * 0.2}
            />
            {/* Adjust the percentage (0.2 in this case) based on your preference */}
          </div>
        </div>
      </div>
      {admit > 1 && ticketCount < admit ? (
        <button
          className="bg-blue-800 p-2 rounded text-white text-sm"
          onClick={handleTicketCount}
        >
          Generate ticket no. {ticketNumber + 1}
        </button>
      ) : null}
      {ticketCount !== 1 ? (
        <button
          className="bg-green-800 p-2 rounded text-white mt-4 text-sm"
          onClick={handlePreviousTicket}
        >
          Back to Ticket No. {ticketNumber - 1}
        </button>
      ) : null}

      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 h-full text-xs"
        onClick={handleDownload}
      >
        Download
      </button>
    </div>
  );
}

export default TicketRender;
