import React from "react";
import { useEffect, useState } from "react";
import { getDocData } from "../Firebase/config";
import { useParams } from "react-router";
import { updateDocFields } from "../Firebase/config";
import Modal from "../components/Modal";
import { useNavigate } from "react-router";

function Validate() {
  const [ticket, setTicket] = useState([]);
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getTicket = async () => {
      const ticket = await getDocData("tickets", id);
      setTicket(ticket);
    };
    getTicket();
  }, [id]);

  const handleClick = () => {
    const data = {
      status: "Validated",
    };
    const update = async () => {
      await updateDocFields("tickets", id, data);
    };
    update();
    setShowModal(false);
    navigate("/ticketvalidation");
  };

  const showModalHandler = () => {
    setShowModal(true);
    console.log(showModal);
  };

  return (
    <div
      className="text-center p-4 max-w-sm m-auto  h-screen
    bg-cover bg-center  bg-no-repeat bg-opacity-10 bg-gray-900"
    >
      <div>
        <h1 className="font-bold uppercase mb-2">Validate</h1>
        <p>Ticket Ref No. {id}</p>
        <p>Buyer: {ticket.name}</p>
        <p>Payment Method: {ticket?.method}</p>
        <p>Amount: {ticket?.cost}</p>
        <p>Payment Status: {ticket?.status}</p>
        <p>Admit: {ticket?.admit}</p>
        <p></p>
        <button
          className="
            bg-green-800 py-2 px-4 rounded text-white  shadow-sm
            "
          onClick={showModalHandler}
        >
          Mark as Validated Payment
        </button>
        <p>Payment Screenshot</p>
        <img src={ticket?.paymentUrl} alt="" />
      </div>
      {showModal && (
        <Modal
          text="Are you sure you want to validate this payment?"
          onClick={handleClick}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
}

export default Validate;
