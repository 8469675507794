import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getDocData } from "../Firebase/config";

function TicketView() {
  const { id } = useParams();
  const [doc, setDoc] = useState({});
  const [formattedDate, setFormattedDate] = useState("");

  useState(() => {
    const getDoc = async () => {
      const doc = await getDocData("tickets", id);
      setDoc(doc);

      const createdOnDate = doc.createdAt.toDate();

      const formattedDate = createdOnDate.toLocaleString(
        undefined,
        dateOptions
      );
      setFormattedDate(formattedDate);
    };
    getDoc();
  }, [id]);

  const tix = doc.ticketNumber?.map((tix) => {
    return tix + " ,";
  });

  const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // Use 24-hour format
  };

  return (
    <div
      className="text-left p-4 max-w-sm m-auto h-screen
    bg-cover bg-center  bg-no-repeat bg-opacity-10 text-xs font-bold text-gray-700 uppercase"
    >
      <div>
        <h1 className="text-blue-800 text-lg text-center mb-8">
          Thank you for your purchase!
        </h1>
        <div className="">
          <div className="shadow-md bg-gray-200 p-2">
            <p className="text-sm">Ticket RefNo.: {id}</p>
            <p className="mb-4 text-sm">Admit: {doc.admit}</p>
            <p>Name: {doc.name}</p>
            <p>Contact Number: {doc.contactNumber}</p>
            <p>Email Address: {doc.email}</p>
            <p>Payment Status: {doc?.status}</p>
            <p>Payment Channel: {doc?.method}</p>
            <p>Payment Date: {formattedDate}</p>
            <p>Amount: Php {doc.cost}</p>
            <p>Ticket Numbers: {tix}</p>
          </div>

          <p className="mt-4 font-bold uppercase">Payment Screenshot</p>
          <img src={doc.paymentUrl} alt="" />
        </div>
      </div>
    </div>
  );
}

export default TicketView;
