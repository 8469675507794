import React from "react";
import { useEffect } from "react";
import useStorage from "../Hooks/useStorage";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";

function ProgressBar({ file, setFile, setInProcess, numTickets, cost, method }) {
  const { url, progress, docId } = useStorage(file, "tickets", numTickets, cost, method);
  const navigate = useNavigate();

  useEffect(() => {
    if (url) {
      setFile(null);
      setInProcess(false);
      navigate(`/ticketinfo/${docId}`);
    }
  }, [url, setFile]);

  return (
    <>
      <div
        className="h-1 bg-blue-500 mt-4"
        style={{ width: progress + "%" }}
      ></div>
      {docId && <Modal docId={docId} />}
    </>
  );
}

export default ProgressBar;
