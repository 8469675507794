import React, { useState, useEffect } from "react";
import Payment from "../components/Payment";
import camasa from "../Assets/camasa.png";
import caritas from "../Assets/caritas.png";
import infinity from "../Assets/infinity.png";
import ayala from "../Assets/ayala.png";

function Home() {
  const [numTickets, setNumTickets] = useState(0);
  const [cost, setCost] = useState(0);


  useEffect(() => {
    setCost(numTickets * 600);
  }, [numTickets]);

  const handleIncrement = () => {
    setNumTickets(numTickets + 1);
  };

  const handleDecrement = () => {
    if (numTickets > 0) {
      setNumTickets(numTickets - 1);
    }
  };

 

  return (
    <div
      className="text-center p-4 max-w-sm m-auto border h-screen
   bg-cover bg-center  bg-no-repeat bg-opacity-10
    "
      /*  style={{ backgroundImage: `url(${background})`
        
    }} */
    >
      <div className="">
        <h1 className="font-bold uppercase">
          Camasa 2023 Movie Block Screening
        </h1>
        <h4 className="font-bold uppercase text-sm text-blue-800 mt-4">Aquaman and the Lost Kingdom</h4>
        <h4 className="font-bold uppercase text-sm text-blue-800 mb-2">December 20, Ayala Malls Circuit Cinema 2, 5:00 PM</h4>
        <h4>Support the Caritas Manila Yslep Scholarship Program</h4>
        <p className="text-xs">
          A fundraising event of Caritas Manila Scholars Association for the
          benefit of Youth Servant Leadership and Education Program (YSLEP) in
          partnership with Caritas Manila Inc.
        </p>
        <div className="flex flex-wrap items-center justify-center gap-3">
          <img className="w-12" src={caritas} alt="" />
          <img className="w-28" src={ayala} alt="" />
          <img className="w-16" src={camasa} alt="" />
          <img className="w-20" src={infinity} alt="" />
        </div>

        <h4 className="font-bold uppercase mt-4">
          Enter number of Tickets Below
        </h4>
        <div className="flex items-center justify-center space-x-2">
          <button
            className="bg-green-800 py-2 px-4 rounded text-white  shadow-sm"
            onClick={handleDecrement}
          >
            -
          </button>
          <input
            className="border border-black rounded p-2 w-1/2 text-center font-bold"
            type="text"
            value={numTickets}
            readOnly
          />
          <button
            className="bg-green-800 py-2 px-4 rounded text-white shadow-sm "
            onClick={handleIncrement}
          >
            +
          </button>
        </div>

        {cost > 0 && <Payment cost={cost} numTickets={numTickets} />}
      </div>
    </div>
  );
}

export default Home;
