import React from "react";

function Modal({ text, onClick, setShowModal }) {
  return (
    <div
      className="
      fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50 z-50
    "
    >
      <div className="bg-white rounded-lg w-1/2 p-6 flex flex-col gap-4 items-center justify-center">
        <p>{text}</p>
        <div className="flex gap-4">
          <button className="bg-green-800 p-2 rounded text-white w-20" onClick={onClick}>Yes</button>
          <button className='border p-2 rounded'onClick={()=> setShowModal(false)}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
