import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import TicketInfo from "./pages/ticketInfo";
import TicketView from "./pages/TicketView";
import TicketValidation from "./pages/TicketValidation";
import Validate from "./pages/Validate";
import TicketRender from "./pages/TicketRender";
import TicketsSummary from "./pages/TicketsSummary";
import Tix from "./pages/TicketViewSummary";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ticketinfo/:id" element={<TicketInfo />} />
          <Route path="/ticket/:id" element={<TicketRender />} />
          <Route path="/ticketview/:id" element={<TicketView />} />
          <Route path="/validate/:id" element={<Validate />} />
          <Route path="/ticketvalidation" element={<TicketValidation />} />
          <Route path="/ticketRender/:id" element={<TicketRender />} />
          <Route path="/summary" element={<TicketsSummary />} />
          <Route path="/tix" element={<Tix />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
