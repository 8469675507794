import React from "react";

function InputBox({ label, placeholder, type, ...otherprops }) {
  return (
    <div className="flex flex-col w-80 mb-4">
      <label className="text-sm font-semibold text-gray-600 text-left" {...otherprops}>
        {label}
      </label>
      <input
        className="w-80 px-4 py-2 mt-0 border rounded-md border-gray-400 focus:outline-none focus:border-blue-500"
        type={type}
        placeholder={placeholder}
        {...otherprops}
      />
    </div>
  );
}

export default InputBox;