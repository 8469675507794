// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDoc, getFirestore, setDoc, getDocs } from "firebase/firestore";

import { doc, collection } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBtaGKYpodnD-UTTwOyh7DyHJkUbQ2vOto",
  authDomain: "mbs-camasa2023.firebaseapp.com",
  projectId: "mbs-camasa2023",
  storageBucket: "mbs-camasa2023.appspot.com",
  messagingSenderId: "519656489236",
  appId: "1:519656489236:web:99ddfe0bf5c67acaabc201",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;

const storage = getStorage(app);
const db = getFirestore(app);

export { storage, db };

//updating individual doc in firebase
export const updateDocFields = async (collectionKey, documentId, data) => {
  try {
    const docRef = doc(db, collectionKey, documentId);
    await setDoc(docRef, data, { merge: true });
    return true;
  } catch (error) {
    console.log(error);
  }
};


//getting individual doc in firebase
export const getDocData = async (collectionKey, documentId) => {
  try {
    const colRef = collection(db, collectionKey);
    const docRef = doc(colRef, documentId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.log(error);
  }
};

//getting all docs in firebase
export const getAllDocuments = async (collectionKey) => {
  try {
    const colRef = collection(db, collectionKey);
    const colSnap = await getDocs(colRef);
    const documents = [];
    colSnap.forEach((doc) => {
      documents.push({ id: doc.id, ...doc.data() });
    });
    return documents;
  } catch (error) {
    console.log(error);
  }
};

//getting all pending documents 
export const getAllPendingDocuments = async (collectionKey) => {
  try {
    const colRef = collection(db, collectionKey);
    const colSnap = await getDocs(colRef);
    const documents = [];
    colSnap.forEach((doc) => {
      if (doc.data().status === "pending validation") {
        documents.push({ id: doc.id, ...doc.data() });
      }
    });
    return documents;
  } catch (error) {
    console.log(error);
  }
};


export const getHighestDocId = async (collectionKey) => {
  try {
    const colRef = collection(db, collectionKey);
    const colSnap = await getDocs(colRef);
    const documents = [];
    colSnap.forEach((doc) => {
      documents.push({ id: doc.id, ...doc.data() });
    });
    const highestId = documents[documents.length - 1].id;
    return highestId;
  } catch (error) {
    console.log(error);
  }
}

export const assignTicketNumber = async (collection, ticketNumber, data) => {
  try {
    const colRef = collection(db, collection);
    const docRef = doc(colRef, ticketNumber);
    await setDoc(docRef, data, { merge: true });
    return true;
  } catch (error) {
    console.log(error);
  }
}