import React from "react";
import { getAllDocuments } from "../Firebase/config";
import { useEffect, useState } from "react";

function TicketsSummary() {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTickets = async () => {
      const allTickets = await getAllDocuments("tickets");
      setTickets(allTickets);
      setLoading(false);
    };
    fetchTickets();
  }, []);

  //arrange tickets by createdAt
  tickets.sort((a, b) => a.createdAt - b.createdAt);

  const { name, ticketNumber, cost, status } = tickets;

  return (
    <div>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <h1>Tickets Summary</h1>
          <table>
            <thead>
              <tr>
                <th>Ticket Number</th>
                <th>Name</th>
                <th>Cost</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map(
                (ticket) =>
                  ticket?.ticketNumber && (
                    <tr key={ticket.id} className="bg-gray-100">
                      <td>
                        {ticket.ticketNumber.map((ticketNumber) => (
                          <span>{ticketNumber}, </span>
                        ))}
                      </td>
                      <a 
                      className="underline"
                      href={`/ticketview/${ticket.id}`} target='_blank'>{ticket.name}</a>
                      <td>{ticket.cost}</td>
                      <td>{ticket.status}</td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default TicketsSummary;
