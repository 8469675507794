import React from "react";
import { useEffect, useState } from "react";
import { getAllPendingDocuments } from "../Firebase/config";
import { useNavigate } from "react-router";

function TicketValidation() {
  const [tickets, setTickets] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getTickets = async () => {
      const tickets = await getAllPendingDocuments("tickets");
      setTickets(tickets);
    };
    getTickets();
  }, []);

  const handleClick = (e, id) => {
    e.preventDefault();

    navigate(`/validate/${id}`);
  };

  return (
    <div
      className="text-center p-4 max-w-sm m-auto  h-screen
    bg-cover bg-center  bg-no-repeat bg-opacity-10 bg-gray-900"
    >
      <h4 className="uppercase font-bold text-center mb-4">
        Payment Validation
      </h4>

      <div className="cards">
        {tickets.map((ticket) => (
          <div
            className="card shadow-md p-1 text-sm mt-4 text-left bg-white text-xs"
            key={ticket.id}
            onClick={(e) => handleClick(e, ticket.id)}
          >
            <p className="font-bold text-blue-900 ">
              Ticket Reference No.: {ticket.id}
            </p>
            <p className="uppercase">Payment Method: {ticket.method}</p>
            <p>Amount: Php {ticket.cost}</p>
            <p>Payment Status: {ticket.status}</p>
          </div>
        ))}
        {
          //if no tickets show paragraph no pending
          tickets.length === 0 && <p>No pending payments</p>
        }
      </div>
    </div>
  );
}

export default TicketValidation;
